import React, { useEffect } from 'react';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";

highchartsMore(Highcharts);

const Chart = (props) => {
const {calValue4}= props


    const options = {
        chart: {
            type: 'gauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            height: 280,
            plotShadow: false
        },
      
        title: {
            text: ''
        },
        exporting: {
            enabled: false
          },
          
        tooltip: {
          borderWidth: 0,
          backgroundColor: "none",
          shadow: false,
          style: {
            fontSize: "16px"
          },
          
        },
        pane: {
            startAngle: -150,
            endAngle: 150,
            background: [{
                backgroundColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, '#FFF'],
                        [1, '#333']
                    ]
                },
                borderWidth: 0,
                outerRadius: '109%'
            }, {
                backgroundColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, '#333'],
                        [1, '#FFF']
                    ]
                },
                borderWidth: 1,
                outerRadius: '107%'
            }, {
                // default background
            }, {
                backgroundColor: '#DDD',
                borderWidth: 0,
                outerRadius: '105%',
                innerRadius: '103%'
            }]
        },
        
      
        yAxis: {
            min: -100,
            max: 100,
        
            minorTickInterval: 'auto',
            minorTickWidth: 1,
            minorTickLength: 10,
            minorTickPosition: 'inside',
            minorTickColor: '#666',
        
            tickPixelInterval: 30,
            tickWidth: 2,
            tickPosition: 'inside',
            tickLength: 10,
            tickColor: '#666',
            labels: {
                step: 2,
                rotation: 'auto'
            },
            title: {
                text: ''
            },
            plotBands: [{
                from: 0,
                to: 40,
                color: '#DDDF0D' // green
            }, {
                from: 40,
                to: 100,
                color: '#55BF3B' // yellow
            }, {
                from: -100,
                to: 0,
                color: '#DF5353' // red
            }]
        },
        
      
        series: [{
            data: [isNaN(calValue4) ? 0 : calValue4],
            // tooltip: {
            //     valueSuffix: ' '
            // }
        }]
      };
    return ( 
        <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
     );
}
 
export default Chart;