import React, { useState, useEffect } from 'react';


const EmojiField = (props) => {
  const { select, selectTop } = props;


  const [Value, setValue] = useState({
    a0: '',
    a1: '',
    a2: '',
    a3: '',
    a4: '',
    a5: '',
    a6: '',
    a7: '',
    a8: '',
    a9: '',
    a10: '',

    b0: '',
    b1: '',
    b2: '',
    b3: '',
    b4: '',

    c0: '',
    c1: '',
    c2: '',
    c3: '',
    c4: '',
    c5: '',
    c6: '',

    d0: '',
    d1: '',
    d2: '',
    d3: '',
    d4: '',
    d5: '',
    d6: '',
    d7: '',
    d8: '',
    d9: '',
  })
  const [calValue1, setCalValue1] = useState(0)
  const [calValue2, setCalValue2] = useState(0)
  const [calValue3, setCalValue3] = useState(0)

  const handleCalValue = (e) => {
    const { name, value } = e.target
    setValue({
      ...Value,
      [name]: value
    })

  }


  useEffect(() => {
    let tempValue = {}
    Object.keys(Value).forEach(element => {
      if (Value[element] === '') {
        tempValue[element] = 0
      } else {
        tempValue[element] = Value[element]
      }
    });


  if(select === "0-10"){
      // a0-10 //
      let { a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10 } = tempValue;
      a0 = parseInt(a0)
      a1 = parseInt(a1)
      a2 = parseInt(a2)
      a3 = parseInt(a3)
      a4 = parseInt(a4)
      a5 = parseInt(a5)
      a6 = parseInt(a6)
      a7 = parseInt(a7)
      a8 = parseInt(a8)
      a9 = parseInt(a9)
      a10 = parseInt(a10)
  
  
      var B = ((a0 + a1 + a2 + a3 + a4 + a5 + a6 + a7 + a8 + a9 + a10) * 11)
      var A = ((a0 * 1) + (a1 * 2) + (a2 * 3) + (a3 * 4) + (a4 * 5) + (a5 * 6) + (a6 * 7) + (a7 * 8) + (a8 * 9) + (a9 * 10) + (a10 * 11))
      var C = B / 11
      var P = ((A * 100) / B)
      var D = A / C;
      var N = ((a9 + a10) / (a0 + a1 + a2 + a3 + a4 + a5 + a6 + a7 + a8 + a9 + a10));
      var top1 = ((a10)/(a0 + a1 + a2 + a3 + a4 + a5 + a6 + a7 + a8 + a9 + a10));
      var top2 = ((a9+a10)/(a0 + a1 + a2 + a3 + a4 + a5 + a6 + a7 + a8 + a9 + a10));
      setCalValue1(((selectTop === "Top-1" ? top1 : top2) * 100).toFixed(2))
      setCalValue2(D.toFixed(2))
      setCalValue3(P.toFixed(2))

  }else if(select === "1-5"){
  // b0 - b4 //
  let { b0, b1, b2, b3, b4 } = tempValue;
  b0 = Number(b0)
  b1 = Number(b1)
  b2 = Number(b2)
  b3 = Number(b3)
  b4 = Number(b4)

  var B = ((b0 + b1 + b2 + b3 + b4) * 5)
  var A = ((b0 * 1) + (b1 * 2) + (b2 * 3) + (b3 * 4) + (b4 * 5))
  var C = B / 5
  var P = ((A * 100) / B)

  var D = A / C;
  var N = ((b2 + b3) / (b0 + b1 + b2 + b3 + b4));
  var top1 = ((b4)/(b0 + b1 + b2 + b3 + b4));
  var top2 = ((b3+b4)/(b0 + b1 + b2 + b3 + b4));
  setCalValue1(((selectTop === "Top-1" ? top1 : top2) * 100).toFixed(2))
  setCalValue2(D.toFixed(2))
  setCalValue3(P.toFixed(2))

  console.log(B, "B");
  console.log(A, "A");
  console.log(C, "C");
  console.log(P, "P");

  console.log(calValue1, "calValue1", calValue2, "calValue2", calValue3, "calValue3");
  }else if(select === "1-7"){
// c0-c6 //

let { c0, c1, c2, c3, c4, c5, c6 } = tempValue;
c0 = Number(c0)
c1 = Number(c1)
c2 = Number(c2)
c3 = Number(c3)
c4 = Number(c4)
c5 = Number(c5)
c6 = Number(c6)
var B = ((c0 + c1 + c2 + c3 + c4 + c5 + c6) * 7)
var A = ((c0 * 1) + (c1 * 2) + (c2 * 3) + (c3 * 4) + (c4 * 5) + (c5 * 6) + (c6 * 7))
var C = B / 7
var P = ((A * 100) / B)

var D = A / C;
var N = ((c5 + c6) / (c0 + c1 + c2 + c3 + c4 + c5 + c6));
var top1 = ((c6)/(c0 + c1 + c2 + c3 + c4 + c5 + c6));
var top2 = ((c5+c6)/(c0 + c1 + c2 + c3 + c4 + c5 + c6));
setCalValue1(((selectTop === "Top-1" ? top1 : top2) * 100).toFixed(2))
setCalValue2(D.toFixed(2))
setCalValue3(P.toFixed(2))

console.log(calValue1, "calValue1", calValue2, "calValue2", calValue3, "calValue3");
  }else if(select === "1-10"){
  // d0-d9//

  let { d0, d1, d2, d3, d4, d5, d6, d7, d8, d9 } = tempValue;
  d0 = Number(d0)
  d1 = Number(d1)
  d2 = Number(d2)
  d3 = Number(d3)
  d4 = Number(d4)
  d5 = Number(d5)
  d6 = Number(d6)
  d7 = Number(d7)
  d8 = Number(d8)
  d9 = Number(d9)
  var B = ((d0 + d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9) * 10)
  var A = ((d0 * 1) + (d1 * 2) + (d2 * 3) + (d3 * 4) + (d4 * 5) + (d5 * 6) + (d6 * 7) + (d7 * 8) + (d8 * 9) + (d9 * 10))
  var C = B / 10
  var P = ((A * 100) / B)

  var D = A / C;
  var N = ((d8 + d9) / (d0 + d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9));
  var top1 = ((d9)/(d0 + d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9));
  var top2 = ((d8+d9)/(d0 + d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9));
  setCalValue1(((selectTop === "Top-1" ? top1 : top2) * 100).toFixed(2))
  setCalValue2(D.toFixed(2))
  setCalValue3(P.toFixed(2))

  console.log(calValue1, "calValue1", calValue2, "calValue2", calValue3, "calValue3");
  }
  })

  const ResetAll = ()=>{
    setValue(Value)
    setCalValue1(calValue1)
    setCalValue2(calValue2)
    setCalValue3(calValue3)
  }


  return (
    <form >
      <div className="row smily-box ">
        {select === "1-5" ? <div className="col-md-12 col-lg-12 col-12 top-five box">
          <h5 className="red text-center ft-bold">1-5</h5>
          <div className="nps-calc-group nps-calc-col nps-calc-group-detractors">
            <div className="nps-calc-group-totals">
              <div className="nps-calc-group-total">
                <div className="heading-smile hide-img">
                  <img src="images/angry-small7.gif" alt="" />
                </div>
                {/* <div className="radial-circle percent" style={{width: '50px', height: '50px', background: '#fff'}}>
                    <p style={{display: 'none'}}>40%</p>
                  </div> */}
                <div className="show-radial start-radiel"><input name='b0' onChange={handleCalValue} value={Value.b0} type="text" min="0" id="b0" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                <div>1</div>
              </div>
              <div className="nps-calc-group-total">
                <div className="heading-smile">
                  <img src="images/angry-small4.gif" alt="" />
                </div>
                <div className="show-radial"><input id="b1" onChange={handleCalValue} value={Value.b1} type="text" min="0" name="b1" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                <div>2</div>
              </div>
              <div className="nps-calc-group-total">
                <div className="heading-smile">
                  <img src="images/nutral-small1.gif" alt="" />
                </div>
                <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} value={Value.b2} name="b2" id="b2" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                <div>3</div>
              </div>
              <div className="nps-calc-group-total">
                <div className="heading-smile">
                  <img src="images/nutral-small2.gif" alt="" />
                </div>
                <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} value={Value.b3} name="b3" id="b3" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                <div>4</div>
              </div>
              <div className="nps-calc-group-total">
                <div className="heading-smile">
                  <img src="images/happy-small1.gif" alt="" />
                </div>
                <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} value={Value.b4} name="b4" id="b4" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input light-green" /></div>
                <div>5</div>
              </div>
            </div>
          </div>
        </div>
          : null}

        {select === "1-7" ?
          <div className="col-md-12 col-lg-12 col-sm-12 col-12 top-seven box">
            <h5 className="mb-0 yellow text-center ft-bold">1-7</h5>
            <div className="nps-calc-group nps-calc-col nps-calc-group-detractors">
              <div className="nps-calc-group-totals">
                <div className="nps-calc-group-total">
                  <div className="heading-smile hide-img">
                    <img src="images/angry-small7.gif" alt="" />
                  </div>
                  <div className="show-radial start-radiel"><input onChange={handleCalValue} type="text" min="0" name="c0" value={Value.c0} id="c0" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>1</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small4.gif" alt="" />
                  </div>
                  <div className="show-radial"><input id="a1" onChange={handleCalValue} type="text" min="0" name="c1" value={Value.c1} autocomplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>2</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small6.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="c2" value={Value.c2} id="c2" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>3</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/nutral-small1.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="c3" value={Value.c3} id="c3" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>4</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/nutral-small2.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="c4" value={Value.c4} id="c4" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>5</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/happy-small1.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="c5" value={Value.c5} id="c5" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>6</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/happy-small2.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="c6" value={Value.c6} id="c6" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>7</div>
                </div>
              </div>
            </div>
          </div>
          : null}

        {select === "1-10" ?
          <div className="col-md-12 col-lg-12 col-sm-12 col-12 top-ten box">
            <h5 className="mb-0 green text-center ft-bold">1-10</h5>
            <div className="nps-calc-group nps-calc-col nps-calc-group-detractors">
              <div className="nps-calc-group-totals">
                <div className="nps-calc-group-total">
                  <div className="heading-smile hide-img">
                    <img src="images/angry-small7.gif" alt="" />
                  </div>
                  <div className="show-radial start-radiel"><input onChange={handleCalValue} type="text" min="0" name="d0" value={Value.d0} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>1</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small4.gif" alt="" />
                  </div>
                  <div className="show-radial"><input onChange={handleCalValue} type="text" min="0" name="d1" value={Value.d1} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>2</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small6.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="d2" value={Value.d2} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>3</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small3.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="d3" value={Value.d3} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>4</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angery-small2.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="d4" value={Value.d4} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>5</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small5.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="d5" value={Value.d5} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>6</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/nutral-small1.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="d6" value={Value.d6} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>7</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/nutral-small2.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="d7" value={Value.d7} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>8</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/happy-small1.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="d8" value={Value.d8} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>9</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/happy-small2.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" min="0" onChange={handleCalValue} name="d9" value={Value.d9} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>10</div>
                </div>
              </div>
            </div>
          </div>
          : null}

        {select === "0-10" ?
          <div className="col-md-12 col-lg-12 col-sm-12 col-12 zero-ten box">
            <h5 className="mb-0 green text-center ft-bold">0-10</h5>
            <div className="nps-calc-group nps-calc-col nps-calc-group-detractors">
              <div className="nps-calc-group-totals">
                <div className="nps-calc-group-total">
                  <div className="heading-smile hide-img">
                    <img src="images/angry-small7.gif" alt="" />
                  </div>
                  <div className="show-radial start-radiel"><input onChange={handleCalValue} name="a0" value={Value.a0} type="text" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>0</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small4.gif" alt="" />
                  </div>
                  <div className="show-radial"><input onChange={handleCalValue} name="a1" value={Value.a1} type="text" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>1</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small6.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a2" value={Value.a2} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>2</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small3.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a3" value={Value.a3} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>3</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angery-small2.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a4" value={Value.a4} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>4</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small5.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a5" value={Value.a5} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>5</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/angry-small6.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a6" value={Value.a6} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>6</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/nutral-small1.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a7" value={Value.a7} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>7</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/nutral-small2.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a8" value={Value.a8} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>8</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/happy-small1.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a9" value={Value.a9} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>9</div>
                </div>
                <div className="nps-calc-group-total">
                  <div className="heading-smile">
                    <img src="images/happy-small2.gif" alt="" />
                  </div>
                  <div className="show-radial"><input type="text" onChange={handleCalValue} name="a10" value={Value.a10} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                  <div>10</div>
                </div>
              </div>
            </div>
          </div>
          : null}
      </div>

      <div className="row  justify-content-center">
        <div className="main-heading count-box-heading col-md-12">
          <h2 className="text-center ">Result</h2>
        </div>

        {/* <h5 onClick={ResetAll}>Reset All</h5> */}
        <div className="col-lg-8 col-lg-8 col-sm-12">

          <div className="row count-box">

            <div className="col-lg-4 col-md-4 col-sm-4 col-4  ">
              <div className="nps-calc-col">
                <div className="nps-calc-group nps-calc-group-promoters">
                  <div className="nps-calc-group-totals">
                    <div className="nps-calc-group-total">
                      <div className="heading-smile">
                      </div>
                      <div className="show-radial"><input disabled type="text" value={isNaN(calValue1) ? '0%' : calValue1 + '%'} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                      <h6 className="ft-bold">Top Box</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4  ">
              <div className="nps-calc-col ">
                <div className="nps-calc-group nps-calc-group-detractors">
                  <div className="nps-calc-group-totals">
                    <div className="nps-calc-group-total">
                      <div className="heading-smile">
                      </div>
                      <div className="show-radial"><input disabled type="text" value={isNaN(calValue2) ? '0' : calValue2} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-promoters input" pattern="[0-9]*" data-group="promoters" /></div>
                      <h6 className="yellow ft-bold">Average</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 ">
              <div className="nps-calc-col">
                <div className="nps-calc-group nps-calc-group-promoters">
                  <div className="nps-calc-group-totals">
                    <div className="nps-calc-group-total">
                      <div className="heading-smile" >
                      </div>
                      <div className="show-radial"><input disabled type="text" pattern="[0-9]*" value={isNaN(calValue3) ? '0%' : calValue3 + '%'} min={0} className="nps-calc-group-total-input input" /></div>
                      <h6 className="green ft-bold">CSAT</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 

          {/* <div className="row count-box">

            {selectTop === "Top 3" ? <div className="col-lg-4 col-md-4 col-sm-4 col-4  ">
              <div className="nps-calc-col">
                <div className="nps-calc-group nps-calc-group-promoters">
                  <div className="nps-calc-group-totals">
                    <div className="nps-calc-group-total">
                      <div className="heading-smile">
                      </div>
                      <div className="show-radial"><input disabled type="text" value={isNaN(calValue1) ? '0%' : calValue1 + '%'} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                      <h6 className="ft-bold">Top Box</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null}
            {selectTop === "Top 2" ? <div className="col-lg-4 col-md-4 col-sm-4 col-4  ">
              <div className="nps-calc-col ">
                <div className="nps-calc-group nps-calc-group-detractors">
                  <div className="nps-calc-group-totals">
                    <div className="nps-calc-group-total">
                      <div className="heading-smile">
                      </div>
                      <div className="show-radial"><input disabled type="text" value={isNaN(calValue2) ? '0' : calValue2} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-promoters input" pattern="[0-9]*" data-group="promoters" /></div>
                      <h6 className="yellow ft-bold">Average</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null}
            {selectTop === "Top 1" ? <div className="col-lg-4 col-md-4 col-sm-4 col-4 ">
              <div className="nps-calc-col">

                <div className="nps-calc-group nps-calc-group-promoters">
                  <div className="nps-calc-group-totals">
                    <div className="nps-calc-group-total">
                      <div className="heading-smile" id={1}>
                      </div>
                      <div className="show-radial"><input disabled type="text" pattern="[0-9]*" name="a0" value={isNaN(calValue3) ? '0%' : calValue3 + '%'} min={0} className="nps-calc-group-total-input input" /></div>
                      <h6 className="green ft-bold">CSAT</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null}
          </div> */}
          
          <button style={{background: "#f85565"}}className="btn btn-info mt-4" onClick={ResetAll}>Clear All</button>
        </div>
      </div>
    </form>
  );
}

export default EmojiField;