import React, { useEffect, useState } from 'react';
import Chart from './chart';

const Export = () => {


  
  const [Value,setValue] = useState({
    a0:'',
    a1:'',
    a2:'',
    a3:'',
    a4:'',
    a5:'',
    a6:'',
    a7:'',
    a8:'',
    a9:'', 
    a10:'',   
  })
  const [calValue1,setCalValue1] = useState (0)
  const [calValue2,setCalValue2] = useState (0)
  const [calValue3,setCalValue3] = useState (0)
  const [calValue4,setCalValue4] = useState (0)


  const handleCalValue=(e)=>{
    const {name,value} = e.target
    setValue({
      ...Value,
    [name]: value
    })
  
  }
  useEffect (()=>{
         
    let tempValue = {}
    Object.keys(Value).forEach(element => {
     if(Value[element]===''){
      tempValue[element] = 0
     }else{
      tempValue[element] = Value[element]
     }
    });

    const {a0,a1,a2,a3,a4,a5,a6,a7,a8,a9,a10} = tempValue;
  var D = (parseInt(a0) + parseInt(a1) + parseInt(a2) + parseInt(a3) + parseInt(a4) + parseInt(a5) + parseInt(a6)) / (parseInt(a0) + parseInt(a1) + parseInt(a2) + parseInt(a3) + parseInt(a4) + parseInt(a5) + parseInt(a6) + parseInt(a7) + parseInt(a8) + parseInt(a9) +parseInt(a10)) ;
  var N = (parseInt(a7) + parseInt(a8)) / (parseInt(a0) + parseInt(a1) + parseInt(a2) + parseInt(a3) + parseInt(a4) + parseInt(a5) + parseInt(a6) + parseInt(a7) + parseInt(a8) + parseInt(a9) +parseInt(a10));
  var P = (parseInt(a9) + parseInt(a10)) / (parseInt(a0) + parseInt(a1) + parseInt(a2) + parseInt(a3) + parseInt(a4) + parseInt(a5) + parseInt(a6) + parseInt(a7) + parseInt(a8) + parseInt(a9) +parseInt(a10));
  var R = P - D
  
  setCalValue1((D* 100).toFixed(2) + '%')
  setCalValue2((N* 100).toFixed(2) + '%')
  setCalValue3((P* 100).toFixed(2) + '%')
  setCalValue4(R.toFixed(2)* 100)
    })
    return ( 
        <div className="site-wrap wrap custom-header">
      
        <div style={{height: '55px'}} />
        <div className="site-wrap">
          {/* <div class="section-space section1">
            <div class="container">
              <div class="main-heading">
                <h2 class="text-center main-heading">Calculate your NPS</h2>
              </div>

              <div class="row">

                <div class="col-md-12 d-flex justify-content-between">
                  <div class="box-icon active promoter d-flex justify-content-center flex-column">
                    <div class="img ">
                      <h6 class="mb-0 green">PROMOTERS</h6>
                      <img src="images/happy-small1.gif">alt=""
                      <img src="images/happy-small2.gif">alt=""

                    </div>
                    <div class="img outer green-box  d-flex">
                      <span class="green">10</span>
                      <span class="green">9</span>
                    </div>

                  </div>
                    <div class="box-icon passives d-flex justify-content-center flex-column">
                      <div class="img">
                        <h6 class="mb-0 yellow">PASSIVES</h6>

                        <img src="images/nutral-small1.gif"alt="">
                        <img src="images/nutral-small2.gif"alt="">

                      </div>
                      <div class="img outer yellow-box d-flex">
                        <span class="yellow">8</span>
                        <span class="yellow">7</span>
                      </div>
                    </div>
                   <div class="box-icon detractor d-flex justify-content-center flex-column">
                    <div class="img">
                      <h6 class="mb-0 red">DETRACTORS</h6>
                      <img src="images/angry-small1.gif">alt=""
                      <img src="images/angery-small2.gif"alt="">
                      <img src="images/angry-small5.gif">alt=""
                      <img src="images/angry-small3.gif">alt=""
                      <img src="images/angry-small6.gif">alt=""
                      <img src="images/angry-small4.gif">alt=""


                      <img src="images/angry-small7.gif">alt=""

                    </div>
                    <div class="img outer red-box d-flex">
                      <span class="red">6</span>
                      <span class="red">5</span>
                      <span class="red">4</span>
                      <span class="red">3</span>
                      <span class="red">2</span>
                      <span class="red">1</span>
                      <span class="red">0</span>
                    </div>

                   </div>

                </div>





              </div>
            </div>
           </div> */}
          <div className="section-space section1">
            <div className="container">
              <div className="main-heading">
                <h2 className="text-center ">Compute your NPS</h2>
                <p className="text-center">Please fill count of responses between 0 to 10.</p>
              </div>
              <form >
                <div className="row smily-box">
                  <div className="col-md-8 col-lg-8 col-12">
                    <h5 className="red text-center ft-bold">DETRACTORS</h5>
                    <div className="nps-calc-group nps-calc-col nps-calc-group-detractors">
                      <div className="nps-calc-group-totals">
                        <div className="nps-calc-group-total">
                          <div className="heading-smile hide-img">
                            <img src="images/angry-small7.gif" alt=""/>
                          </div>
                          {/* <div className="radial-circle percent" style={{width: '50px', height: '50px', background: '#fff'}}>
                            <p style={{display: 'none'}}>40%</p>
                          </div> */}
                          <div className="show-radial start-radiel"><input onChange={handleCalValue} type="text"  name='a0' value={Value.a0} id="a0" autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                          <div>0</div>
                        </div>
                        <div className="nps-calc-group-total">
                          <div className="heading-smile">
                            <img src="images/angry-small4.gif" alt=""/>
                          </div>
                          <div className="show-radial"><input id="a1" onChange={handleCalValue} type="text"  name='a1' value={Value.a1}autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                          <div>1</div>
                        </div>
                        <div className="nps-calc-group-total">
                          <div className="heading-smile">
                            <img src="images/angry-small6.gif" alt=""/>
                          </div>
                          <div className="show-radial"><input onChange={handleCalValue}  type="text"  id="a2" name='a2' value={Value.a2} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                          <div>2</div>
                        </div>
                        <div className="nps-calc-group-total">
                          <div className="heading-smile">
                            <img src="images/angry-small3.gif" alt=""/>
                          </div>
                          <div className="show-radial"><input onChange={handleCalValue} type="text"  id="a3" name='a3' value={Value.a3} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                          <div>3</div>
                        </div>
                        <div className="nps-calc-group-total">
                          <div className="heading-smile">
                            <img src="images/angery-small2.gif"alt="" />
                          </div>
                          <div className="show-radial"><input  onChange={handleCalValue} type="text"  id="a4" name='a4' value={Value.a4} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                          <div>4</div>
                        </div>
                        <div className="nps-calc-group-total">
                          <div className="heading-smile">
                            <img src="images/angry-small5.gif" alt=""/>
                          </div>
                          <div className="show-radial"><input onChange={handleCalValue} type="text"  id="a5" name='a5' value={Value.a5} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                          <div>5</div>
                        </div>
                        <div className="nps-calc-group-total">
                          <div className="heading-smile">
                            <img src="images/angry-small1.gif" alt=""/>
                          </div>
                          <div className="show-radial"><input onChange={handleCalValue} type="text"  id="a6" name='a6' value={Value.a6} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                          <div>6</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-lg-2 col-sm-6 col-6">
                    <h5 className="mb-0 yellow text-center ft-bold">PASSIVES</h5>
                    <div className="nps-calc-col ">
                      <div className="nps-calc-group nps-calc-group-promoters">
                        <div className="nps-calc-group-totals">
                          <div className="nps-calc-group-total">
                            <img src="images/nutral-small2.gif"alt="" />
                            <div className="show-radial"><input onChange={handleCalValue} type="text"  id="a7" name='a7' value={Value.a7} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-promoters input" pattern="[0-9]*" data-group="promoters" /></div>
                            <div>7</div>
                          </div>
                          <div className="nps-calc-group-total">
                            <div className="heading-smile">
                              <img src="images/nutral-small1.gif"alt="" />
                            </div>
                            <div className="show-radial"><input onChange={handleCalValue} type="text"  id="a8" name='a8' value={Value.a8} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-promoters input" pattern="[0-9]*" data-group="promoters" /></div>
                            <div>8</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-lg-2 col-sm-6 col-6">
                    <h5 className="mb-0 green text-center ft-bold">PROMOTERS</h5>
                    <div className="nps-calc-col">
                      <div className="nps-calc-group nps-calc-group-promoters">
                        <div className="nps-calc-group-totals">
                          <div className="nps-calc-group-total">
                            <div className="heading-smile">
                              <img src="images/happy-small2.gif" alt=""/>
                            </div>
                            <div className="show-radial"><input  onChange={handleCalValue} type="text"  id="a9" name='a9' value={Value.a9} autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-promoters input" pattern="[0-9]*" data-group="promoters" /></div>
                            <div>9</div>
                          </div>
                          <div className="nps-calc-group-total">
                            <div className="heading-smile">
                              <img src="images/happy-small1.gif" alt=""/>
                            </div>
                            <div className="show-radial"><input onChange={handleCalValue} type="text"  name='a10' value={Value.a10} pattern="[0-9]*" name="a10" id="a10"  min={0} className="nps-calc-group-total-input input" /></div>
                            <div>10</div>
                          </div>
                        </div>
                        <div className="nps-calc-group-connector nps-calc-group-connector-straight nps-calc-group-connector-straight-promoters nps-calc-group-connector-straight-image-promoters input" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  justify-content-center">
                  <div className="main-heading count-box-heading col-md-12">
                    <h2 className="text-center ">Your Percentage of:-</h2>
                  </div>
                  <div className="col-lg-8 col-lg-8 col-sm-12">
                    <div className="row count-box">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <div className="nps-calc-col">
                          <div className="nps-calc-group nps-calc-group-detractors">
                            <div className="nps-calc-group-totals">
                              <div className="nps-calc-group-total">
                                <div className="heading-smile">
                                  <img src="images/angry-small7.gif" alt=""/>
                                </div>
                                <div className="show-radial"><input disabled type="text" value={calValue1 && calValue1.includes('NaN')===true ? '0%' : calValue1}  autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-detractors input" /></div>
                                <h6 className="ft-bold">DETRACTORS</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <div className="nps-calc-col ">
                          <div className="nps-calc-group nps-calc-group-promoters">
                            <div className="nps-calc-group-totals">
                              <div className="nps-calc-group-total">
                                <div className="heading-smile">
                                  <img src="images/nutral-small1.gif"alt="" />
                                </div>
                                <div className="show-radial"><input disabled type="text" value={calValue2 && calValue2.includes('NaN')===true ? '0%' : calValue2}  autoComplete="off" className="nps-calc-group-total-input nps-calc-group-total-input-promoters input" pattern="[0-9]*" data-group="promoters" /></div>
                                <h6 className="yellow ft-bold">PASSIVES</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <div className="nps-calc-col">
                          <div className="nps-calc-group nps-calc-group-promoters">
                            <div className="nps-calc-group-totals">
                              <div className="nps-calc-group-total">
                                <div className="heading-smile" id={1}>
                                  <img src="images/happy-small1.gif" alt=""/>
                                </div>
                                <div className="show-radial"><input disabled type="text"  pattern="[0-9]*" name="a0"  value={calValue3 && calValue3.includes('NaN')===true ? '0%' : calValue3} min={0} className="nps-calc-group-total-input input" /></div>
                                <h6 className="green ft-bold">PROMOTERS</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  justify-content-center result">
                  <div className="col-md-6">
                    <div className="nps-calc-group nps-calc-group-total">
                      <div className="heading-smile">
                        <h3>NPS</h3>
                      </div>
                      <Chart calValue4={calValue4}/>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
     );
}
 
export default Export;