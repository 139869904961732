import React, { useState, useEffect } from 'react';
import EmojiField from '../common/emojiField';

const optionValue =[
    "Choose Scale",
    "1-5",
    "1-7",
    "1-10",
    "0-10"
]

const topOption =[
  "Top-1",
  "Top-2",
]
const Csat = () => {


    const [select,setSelect] = useState("Choose Scale")   
    const [selectTop,setSelectTop] = useState("Top-1") 

const handleSelect =(e)=>{
  const {value} = e.target;
    setSelect(value)
}
const handleSelectTop =(e)=>{
  const {value} = e.target;
    setSelectTop(value)
    console.log(selectTop);
}



    return ( 
        <div className="site-wrap wrap custom-header">
     
        <div style={{height: '55px'}} />
        <div className="site-wrap">
          <div className="section-space section1">
            <div className="container">
              <div className="main-heading">
                <h2 className="text-center ">Settings</h2>
              </div>
              <div className="row smily-box mb-4">
                <div className="col-md-6 col-lg-6 col-12">
                  <h5 className="red text-center ft-bold">Scale</h5>
                  <div className="nps-calc-col text-center col-md-8 m-auto">
                  <select  name='select' value={select} onChange={handleSelect} className="form-control">
                  {optionValue.map((optionValue)=>(<option  value={optionValue}>{optionValue}</option>))}
              </select>   
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-6">
                  <h5 className=" yellow text-center ft-bold"> Top Box</h5>
                  <div className="nps-calc-col ">
                    <div className="nps-calc-col text-center col-md-8 m-auto">
                      <select name='selectTop' value={selectTop} onChange={handleSelectTop} placeholder="" className="form-control">
                    {topOption.map((topOption)=>(<option  value={topOption}>{topOption}</option>))}
                      
                    </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-heading mb-2">
                <h2 className="text-center ">Enter number of respondents</h2>
              </div>
              <EmojiField selectTop={selectTop} select={select} />
            </div>
          </div>
        </div>
      </div>
     );
}
 
export default Csat;