import React, { useState, Fragment, useEffect } from 'react';




const SampleSizeCalculator = () => {

  // const [selectValue,setSelectValue] = useState('')
  // const [changeValue,setChangeValue] = useState()

  // const handleSelect =(e)=>{
  //   const {value} = e.target;
  //   setSelectValue(value)
  //   console.log(selectValue);
  // }

  // const handleChangeValue =(e)=>{
  //   const {value} = e.target
  //   setChangeValue(value)
  //   console.log(changeValue,">>>>>>>");
  // }


  const [Value, setValue] = useState({
    a0: '',
    a1: 1.28,
    a2: '',
  })
  const [calValue, setCalValue] = useState(0)


  useEffect(() => {
    let tempValue = {}
    Object.keys(Value).forEach(element => {
      if (Value[element] === '') {
        tempValue[element] = 0
      } else {
        tempValue[element] = Value[element]
      }
    });

    
  //  let p = 0.5;
  //  let q= 1-p;
    let { a0, a1, a2 } = tempValue;
    console.log(tempValue,'tempValue');
    a0 = Number(a0)
    a1 = Number(a1)
    a2 = Number(a2)
  // console.log(a1,"a1");
  // var n0 =  a1*a1// p*q//(((a1*a1)(p*q))/(a2*a2))
  //   console.log(n0.toFixed(2),"n0>>>>>>>>>");
   var N = ((a1*a1)*(0.5*0.5))/((a2*a2)/10000)
   var D = ((a1*a1)*(0.5*0.5))/(((a2*a2)/10000)*a0)
   var R = ((N/(1+D)))
   
   setCalValue(Math.round(R))

    
  })


  const handleCalValue = (e) => {
    const { name, value } = e.target
    setValue({
      ...Value,
      [name]: value
    })
    console.log(value, "value>>>");
  }

  return (

    <Fragment>
      <div style={{ height: '46px' }} />
      <div className="container-fluid bg-light text-center pt-4 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-5 mb-5">
              <h2 className="text-center">Calculate your sample size</h2>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="email">Population Size</label>
                <input type="email" name="a0" onChange={handleCalValue} value={Value.a0} className="form-control" placeholder="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="email">Confidence Level (%)</label>
                <select onChange={handleCalValue} value={Value.a1} name="a1" className="custom-select">
                  <option value="1.28">80</option>
                  <option value="1.44">85</option>
                  <option value="1.65">90</option>
                  <option value="1.96">95</option>
                  <option value="2.58">99</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="email">Margin of Error (%)</label>
                <input type="text" onChange={handleCalValue} value={Value.a2} name="a2" className="form-control" placeholder="5" />
              </div>
            </div>
            <div className="col-lg-12 mt-5 mb-5">
              <h5 className="text-center">Sample size</h5>
              <h1 className="text-info" style={{ fontSize: '74px' }}>{isNaN(calValue) ? 0: calValue}</h1>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SampleSizeCalculator;