import React from 'react';
import './App.css';
import Csat from './components/csat';
import Header from './components/header';
import { BrowserRouter, Route } from 'react-router-dom';
import SampleSizeCalculator from './components/sampleSizeCalculator';
import Export from './components/export';


function App() {

  return (
    <div className="App">
       <BrowserRouter>
       <Header/>
                <Route path="/" component={Export} exact />
                <Route path="/csat" component={Csat} />
                <Route path="/sampleSizeCalculator" component={SampleSizeCalculator} />
            </BrowserRouter>
    </div>
  );



  
}

export default App;
